import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { ContactFooter } from "./components/contactFooter";
import Products from "./components/products/products";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div style={{ overflowX:"hidden" }}>
      <Navigation />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />


      <Contact data={landingPageData.Contact} />
      <Services data={landingPageData.Services} />
      <Products />

      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Maximum Fall Protection, LLC.
          </p>
        </div>
        <div style={{height: 100}}></div>
      </div>

      <ContactFooter />
    </div>
  );
};

export default App;
