import React from "react";

export const ContactFooter = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-bottom">
      <div className="container">
        <div className="navbar-header">
          
          <a className="navbar-brand page-scroll" href="#contact">
            CONTACT US NOW!
          </a>{" "}
        </div>

        
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a>
                <a href="mailto:sales@maxfallpro.com">sales@maxfallpro.com</a>
              </a>
            </li>
            <li>
              <a>
              (602)-858-9200
              </a>
            </li>
            {/* <li>
              <a href="#portfolio" className="page-scroll">
                Gallery
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li> */}
          </ul>
        </div>

    </nav>
  );
};
