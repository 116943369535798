import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title col-xs-11">
          <h2>Servicing & Recertification</h2>
          <p>
          We are committed to providing top quality customer service and our highly professional team is proud to offer in-house servicing and recertification of IKAR devices. The steps of our process are listed below. Contact us now with any questions or to schedule service!
          </p>
        </div>
        <div className="row" >
          {props.data
            ? props.data.map((d, i) => (
              <>
                <div key={`${d.name}-${i}`} className="col-md-6 col-xs-11">
                  {" "}
                  <img style={{height: 200}} src={require("../images/ikar.png")}className={d.icon}></img>
                  <a style={{marginTop: 50}}
                  href="https://firebasestorage.googleapis.com/v0/b/max-fall-pro.appspot.com/o/MFP%20RMA%20Request%20Form%20.docx?alt=media&token=8f7dceae-bd3a-4024-9d06-790fb0e0b1e1"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Download RMA Request Form
                </a>{" "}
                <a style={{marginTop: 50}}
                  href="https://firebasestorage.googleapis.com/v0/b/max-fall-pro.appspot.com/o/MFP%20Inspection%20Sheet.pdf?alt=media&token=b4840bda-9bfa-4e26-b623-2e9eef4a3447"
                  className="btn btn-custom btn-lg page-scroll"
                  target="_blank"
                >
                  Download MFP Inspection Sheet
                </a>{" "}
                    </div>

                    <div key={`${d.name}-${i}-2`}  className="col-md-6 col-xs-11">
                    <div className="service-desc">
                    <h3>{d.name}</h3>
                   
                    <div style={{textAlign: "left", fontSize: 20}}>
                    
                      <div>1. Download and complete the <a href="https://firebasestorage.googleapis.com/v0/b/max-fall-pro.appspot.com/o/MFP%20RMA%20Request%20Form%20.docx?alt=media&token=8f7dceae-bd3a-4024-9d06-790fb0e0b1e1">RMA Request Form</a></div>
                      <div>2. You can scan and email the form to: <a href="mailto:repairs@maxfallpro.com">repairs@maxfallpro.com</a></div>
                      <div>3. Purchase Order should be made out for $75.00 for an initial diagnostic inspection per device.</div>
                      <div>4. The RMA Number request will be processed.</div>
                      <div>5. You will be emailed an RMA Number for your equipment along with the ship to address to our repair center.</div>
                      <div>6. Make sure the RMA Number is prominently displayed on all packaging and paperwork.</div>
                      <div>7. Failure to ship equipment without an assigned RMA Number will result in the equipment being returned unrepaired.</div>
                      <div>8. Once in receipt of your equipment an inspection report will be generated, and a notification will be sent regarding the total cost of repair(s). If the repair is decided to be made, the initial inspection fee will be waived.</div>
                      <div>9. All shipping and handling costs are the customer’s responsibility.</div>
                      <div>10. Thank you for trusting us with your equipment. </div>
                    </div>
                   
                    </div>
                    </div>
                    </>

              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
