import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-11 col-md-5">
            {" "}
            <img src="img/convention3.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-11 col-md-6">
            <div className="about-text">
              <h2>About Max Fall Pro</h2>
              <p style={{fontSize: 20}}>{ `We are the premier North American distributor and maintenance facility of all IKAR retractable devices, class 1 and 2 SRLs, SRL-Ps, SRL-LEs and SRL-Rs. The innovative technology of Max Fall Pro devices meets and/or exceeds the requirements set forth by ANSI Z359.14-2021 and OSHA 1926.502, 1910.66, and 1910.140. With over 40 years of experience in the development of SRLs and the technical performance of our maintenance department, we are confident you will be satisfied with our team's expertise and professionalism.`}</p>
              <p style={{fontSize: 20, marginTop: 20}}>{ `Our primary focus is to ensure the highest level of safety for our users by constantly staying on the forefront of technological advancements and anticipating developments in the safety field.`}</p>
              {/* <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div> */}
                {/* <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div> 
                      </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
