import React from "react";

import  ProductTileList  from "./productTileList"
import { data } from "../../data/product_data";
import { csvToArr } from "../../utils/utils";

const products = csvToArr(data, ",");

console.log(products);

export default function Products(){
    return  <div id="products" style={{background: "linear-gradient(to right, #6372ff 0%, #5ca9fb 100%)"}}>
    <div id="contact" style={{padding: 20, background: null}}>
      <div className="col-md-12">
        <div className="row">
          <div className="section-title">
            <h2>Products</h2>
            {/* <p>
              Please fill out the form below to send us an email and we will
              get back to you as soon as possible.
            </p> */}
          </div>
          </div>
    </div>
    </div>

        <ProductTileList products={products}/>

    </div>
}
