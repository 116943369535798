import React from "react";
import { Box, Grid } from "@mui/material";
import ProductTile from "./productTile";
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 1,
    width: "70%",
    bgcolor: 'background.paper',
  //   border: '2px solid #000',
    boxShadow: 24,

  };
  

export default function ProductTileList(props){
    const [selectedItem, setSelectedItem] = React.useState(null)

    function renderTile(current_item, onClick){
        return  <Grid item xs={12} sm={6} md={4}><ProductTile product={current_item} onClick={onClick}></ProductTile></Grid>;
    }

    function handleClose(){
        setSelectedItem(null);
    }

    // Iterates through the product list and calls the renderTile
    // method to create the ProductTile objec
    let tiles = [];
    for (let i = 0; i < props.products.length; i++) {
        const current_item = props.products[i];
        tiles.push(renderTile(current_item, ()=>setSelectedItem(i)));
    }
    return <div style={{padding: 20}}>
        <Modal
        open={selectedItem !== null}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <ProductTile product={props.products[selectedItem]} isFullSize={true}></ProductTile>
        </Box>
      </Modal>
        <Grid container spacing={2}>
        {tiles}
        </Grid></div>;
    
}