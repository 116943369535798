import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import logo from '../../images/logo_text.png'
import Carousel from 'react-material-ui-carousel'


const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '90%',
});

const logo_image = <div style={{position: 'absolute', top: 10, right: 10}}><img style={{width: "10vw", opacity: 0.7}} src={logo}></img></div>
const logo_image_small = <div style={{position: 'absolute', top: 0, right: 0}}><img style={{width: 55, opacity: 0.7}} src={logo}></img></div>


export default function ProductTile(props) {
    const {product, onClick, isFullSize} = props;

    const buttonSize = isFullSize ? "80px" : "80px";

    const imageSize = isFullSize ? "100%" : 120;

    const images = [product.image_1 || logo, product.image_2, product.image_3].filter((item)=>!!item);
    
   return (
    <Paper
      sx={{
        p: 2,
        margin: 'auto',
        maxWidth: isFullSize ? "100%" : 500,
        flexGrow: 1,
        minHeight: 210,
        backgroundColor: "#fff"}}
      onClick={onClick}
    >
      <Grid container spacing={2}>
        <Grid xs={isFullSize ? 12 : "auto"} item>
          
          { isFullSize && images.length > 1?
          <Carousel autoPlay={false} an>
            
            {
                [product.image_1 || logo, product.image_2, product.image_3].filter((item)=>!!item).map( (item, i) => <div><Paper ><Img style={{maxHeight: "70vh"}} alt="complex" src={item} /></Paper>{logo_image}</div> )
            }
        </Carousel>
        :
        <ButtonBase sx={{ width: imageSize}}><Img alt="complex" src={product.image_1 || logo} />{product.image_1 ? isFullSize ? logo_image : logo_image_small: null}</ButtonBase>
        }
            
          
        </Grid>
        <Grid item xs={12} sm container spacing={2}>
          <Grid item xs container direction="column" spacing={1}>
            <Grid item xs>
              <Typography gutterBottom variant="title" component="div" style={{fontWeight: "bold"}}>
              {product.item_description}
              </Typography>
              <Typography variant="body1" gutterBottom>
              {product.item_number}
              </Typography>
              <Typography variant="body2" color="text.secondary">
              {product.item_product_group}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
          {product.cot && <Button size="large" href={product.cot} target="_blank"><Typography variant="h6">Certificate Of Test</Typography></Button>}
          {product.instructions && <Button size="large" href={product.instructions} target="_blank"><Typography variant="h6">Instructions</Typography></Button>}
          {product.doc && <Button size="large" href={product.doc} target="_blank"><Typography variant="h6">Declaration of Conformity</Typography></Button>}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}


// import React from "react";
// import './styles.css'


// export default function ProductTile(props){
//     return (
//         <div class="card">
//             <div class="card-image">
//                 <figure class="image is-4by3">
//                     <img src={props.product.image} alt="Placeholder image"></img>
//                 </figure>
//             </div>
//             <div class="card-content">
//                 <p class="title product-title">{props.product.name}</p>

//                 <div class="content">
//                     {props.product.short_description}
//                     <br></br>
//                 </div>
//                 <a class="button is-primary" href={"product.html?id=" + props.product.id.toString()} target="_blank">
//                     <strong>More Details</strong>
//                 </a>
//             </div>
//         </div>
//     )
// }