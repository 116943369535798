import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {createRoot} from 'react-dom/client';
import {APIProvider, Map, Marker, AdvancedMarker, Pin, useAdvancedMarkerRef, useMarkerRef, InfoWindow, } from '@vis.gl/react-google-maps';
import Autocomplete, {usePlacesWidget} from "react-google-autocomplete";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import CircularProgress from '@mui/material/CircularProgress';
import { types } from 'util';
import { Input, TextField, Paper, Grid } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const mapsAPIKey = "AIzaSyCemvkj1xQnmk1lQBWHz1Gb5WfoSiY1hiM"



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 1,
//  width: 400,
  bgcolor: 'background.paper',
//   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const markerPositions = [[41.396839, -81.8632006], 
[37.8002995, -121.1675685],
[33.7601507, -84.5693279], //Atlanta GA (general)
[40.6932307, -74.2256756],
[40.7178994, -111.9549594]

]
const infoWindowContent = [
  <p>You are here</p>, 
  <div className="contact-info">
      <h5>MAZZELLA</h5>
      <p>21000 AEROSPACE PARKWAY</p>
      <p>CLEVELAND, OH 44142</p>
      <p>(440) 239-7000</p>
      <a href='https://www.mazzellacompanies.com/' target="_blank">https://www.mazzellacompanies.com/</a>
  </div>,
  <div className="contact-info">
      <h5>ALL RIGGING COMPANY</h5>
      <p>17800 COMCONEX RD</p>
      <p>MANTECA, CA 95336</p>
      <p>(209) 418-7125</p>
      <a href='https://allrigging.com/' target="_blank">https://allrigging.com/</a>
  </div>,
  <div className="contact-info">
      <h5>ASHLEY SLING, LLC</h5>
      <p>P.O. BOX 44413</p>
      <p>ATLANTA, GA 30336</p>
      <p>(404) 691-2604</p>
      <a href='https://ashleysling.com/' target="_blank">https://ashleysling.com/</a>
  </div>,
  <div className="contact-info">
      <h5>BILCO WIRE ROPE</h5>
      <p>1285 CENTRAL AVE</p>
      <p>HILLSIDE, NJ 07205</p>
      <p>(908) 351-7800</p>
      <a href='https://www.bilcogroup.com/' target="_blank">https://www.bilcogroup.com/</a>
  </div>,
  <div className="contact-info">
      <h5>ROCKY MOUNTAIN WIRE ROPE &amp; RIGGING</h5>
      <p>2421 S 2570 W</p>
      <p>SALT LAKE CITY, UT 84119</p>
      <p>(801) 972-4972</p>
      <a href='https://wirerope.net/rmwr/about-us/' target="_blank">https://wirerope.net/rmwr/about-us/</a>
  </div>
]

export default function MapModal() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [coords, setCoords ] = React.useState([])
  const [map, setMap]= React.useState(null)
  const [isFetching, setIsFetching ] = React.useState(false);
  const [selfMarkerRef, selfMarker] = useAdvancedMarkerRef();

  const [markerRef1, marker1] = useMarkerRef();
  const [markerRef2, marker2] = useMarkerRef();
  const [markerRef3, marker3] = useMarkerRef();
  const [markerRef4, marker4] = useMarkerRef();
  const [markerRef5, marker5] = useMarkerRef();

  const { ref: materialRef } = usePlacesWidget({
    apiKey: mapsAPIKey,
    options: {
        types: [],
      },
    onPlaceSelected: (place) => {
        setCoords([]);
        const c = [place.geometry.location.lat(), place.geometry.location.lng()]
        console.log(c)
        setCoords(c);
    }
  });

  const markers = [selfMarker, marker1, marker2, marker3, marker4, marker5]
  const markerRefs = [selfMarkerRef, markerRef1, markerRef2, markerRef3, markerRef4, markerRef5]

  const [infoWindowShown, setInfoWindowShown] = React.useState([false, true, true, true, true, true]);

  function handleMarkerClick(i){
    console.log(infoWindowShown);
    infoWindowShown[i] = true;
    setInfoWindowShown([...infoWindowShown]);
  }

  function handleCloseInfo(i){
    console.log(infoWindowShown);
    infoWindowShown[i] = false;
    setInfoWindowShown([...infoWindowShown]);
  }

  React.useEffect(()=>{
    if (coords.length) setOpen(true);
    else setOpen(false);
},[coords])

React.useEffect(()=>{
console.log("infoWindowShown",infoWindowShown)
},[infoWindowShown])

  function getCurrentLocation(){
    if ("geolocation" in navigator) {
        setIsFetching(true)
        console.log("Available");
        navigator.geolocation.getCurrentPosition(function(position) {
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
            console.log("map", map);
            setCoords([position.coords.latitude, position.coords.longitude]); 
            setIsFetching(false)    
          });
    } else {
        console.log("Not Available");
        setIsFetching(null)
    }
  }

  return (
    <div style={{margin: 50}}>
        <div style={{ display: "flex" }}>
        <Paper
      sx={{
        p: 2,
        margin: 'auto',
        padding: 5,
        maxWidth: 400,
        flexGrow: 1,
        backgroundColor: "rgba(255, 255, 255, 0.9)"}}
    >
        <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}> <h2 style={{width: "100%"}}>Locate Vendor</h2></Grid>
        <Grid item xs={12} sm={2} >        <div>

      
{("geolocation" in navigator) ? <Button  onClick={getCurrentLocation}>{isFetching ? <CircularProgress style={{height: 35, width: 35}}/>:<MyLocationIcon style={{height: 35, width: 35}}/>}</Button> : null}
</div></Grid>
        
<Grid item xs={12} sm={10} >
      <div>
        {/* <Input
            fullWidth
            inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                <Autocomplete
                options={{
                    types: [],
                    
                  }}
                apiKey={mapsAPIKey}
                onPlaceSelected={(place) => {
                    setCoords([]);
                    const c = [place.geometry.location.lat(), place.geometry.location.lng()]
                    console.log(c)
                    setCoords(c);
                }}
        />
            )}
          /> */}

<div >
          <TextField
            fullWidth
            variant="outlined"
            inputRef={materialRef}
          />
        </div>
          </div>
          </Grid>
        </Grid>
    </Paper>
        
          </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx={style}>
        <APIProvider apiKey={mapsAPIKey}>
        {open && <div>
          <div style={{position: 'absolute', right: 5, top: 5, height: 30, width: 30 }}>
<CloseIcon onClick={handleClose} style={{height: 30, width: 30}}/>
          </div>
          <Map
        mapId={"be841ea701d019b5"}
      style={{width: '80vw', height: '80vh'}}
      defaultCenter={{lat: coords[0], lng: coords[1]}}
      defaultZoom={6}
      gestureHandling={'greedy'}
      onLoad={(map)=>{console.log("map1", map); setMap(map)}}
    //   disableDefaultUI={true}
    >
    <AdvancedMarker  onClick={()=>handleMarkerClick(0)} ref={selfMarkerRef} position={{lat: coords[0], lng: coords[1]}}>
        <Pin
        background={'#0f9d58'}
        borderColor={'#006425'}
        glyphColor={'#60d98f'}
        />
       
    </AdvancedMarker>
    {
      markers && markers.map((marker, i)=>(

        i && <Marker ref={markerRefs[i]}  onClick={()=>handleMarkerClick(i)} position={{lat: markerPositions[i-1][0], lng: markerPositions[i-1][1]}} />

      ))
    }

    
    {infoWindowShown && infoWindowShown.map((shown, i)=>
        (shown && <InfoWindow  disableAutoPan anchor={markers[i]} onClose={()=>handleCloseInfo(i)} >
        {infoWindowContent[i]}
      </InfoWindow>))
    }
    </Map>
    </div>}
    
    
  </APIProvider>
        </Box>
      </Modal>
    </div>
  );
}