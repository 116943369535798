import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
const boxStyle={border:"0px solid white", borderColor: "white", padding: 10, borderRadius: 10, margin: 0, }
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
      <div id="contact">
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                {/* <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p> */}
              </div>
            <div style={boxStyle}  className="col-md-3 col-xs-6  contact-info">
            <p style={{fontWeight: "bold", color: "white"}}>Sales & Repairs</p>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                (602)-858-9200
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                <div>
                <a style={{color: "white"}} href={"mailto:sales@maxfallpro.com"}>sales@maxfallpro.com</a>
                </div>
                <div>
                <a style={{color: "white"}} href={"mailto:repairs@maxfallpro.com"}>repairs@maxfallpro.com</a>
                </div>
              </p>
            </div>
          </div>
          <div style={boxStyle} className="col-md-3 col-xs-6 contact-info">
            <p style={{fontWeight: "bold", color: "white"}}>Jon Schryver</p>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                (610)-662-5320
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                <a style={{color: "white"}} href={"mailto:jon@maxfallpro.com"}>jon@maxfallpro.com</a>
              </p>
            </div>
          </div>
          <div style={boxStyle} className="col-md-3 col-xs-6  contact-info">
            <p style={{fontWeight: "bold", color: "white"}}>Robert Mendoza</p>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                (602)-292-0335
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                <a style={{color: "white"}} href={"mailto:robert@maxfallpro.com"}>robert@maxfallpro.com</a>
              </p>
            </div>
          </div>
          <div className="col-md-3 col-xs-6 contact-info">
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                2339 N 34th Dr, Phoenix, AZ 85009
              </p>
            </div>
            <img  style={{width: "50%", maxWidth: 300, opacity: "100%",}} src={require("../images/logo_text.png")}></img>
          </div>
          
              {/* <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form> */}
            </div>
          </div>


          {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
  );
};
